import {
  MediaFile,
  PaymentCard,
} from "src/employee/onboarding-v2/components/types";

export interface Address {
  city: string;
  country: string;
  postalCode: string;
  state: string;
  street1: string;
  street2: string | null;
}

export interface Administrator {
  avatar: MediaFile | null;
  displayName: string;
  id: number;
  firstName: string;
  lastName: string;
  role: string;
  type: UserType.ADMINISTRATOR;
}

export interface AmazonConnectConfig {
  contactId: string;
  lastContactId: string | null;
  participantId: string;
  participantToken: string;
  region: "us-east-1";
}

export enum App {
  ONBOARDING = "onboarding",
  PORTAL = "portal",
  PORTAL_GROUPS = "portal-groups",
  MESSAGES = "messages",
}

export interface ChatItem {
  contactId: string | null;
  content: string;
  createdAt: string;
  id: string;
  participantId: string;
  participantRole: string | null;
  type: string;
  user: ChatParticipant;
}

export type ChatParticipant =
  | ChatParticipantAgent
  | ChatParticipantCustomer
  | ChatParticipantSystem;

export interface ChatParticipantAgent {
  avatar: MediaFile | null;
  displayName: string;
  id: number | string;
  firstName: string;
  lastName: string;
  role: string;
  type: ChatParticipantType.AGENT;
}

export interface ChatParticipantCustomer {
  avatar: MediaFile | null;
  displayName: string;
  firstName: string | null;
  id: number | string;
  lastName: string | null;
  type: ChatParticipantType.CUSTOMER;
}

export interface ChatParticipantSystem {
  avatar: MediaFile | null;
  displayName: string;
  id: number | string;
  firstName: string;
  lastName: string;
  role: string;
  type: ChatParticipantType.SYSTEM;
}

export enum ChatParticipantType {
  AGENT = "AGENT",
  CUSTOMER = "CUSTOMER",
  SYSTEM = "SYSTEM",
}

export enum ChatUserType {
  ADMINISTRATOR = 0,
  AGENT = 1,
  END_USER = 2,
}

export interface DemoConfiguration {
  alternativeFundingSourcesEnabled: boolean;
  companyEmailDomain: string;
  companyLogo: string;
  hideDirectDeposit: boolean;
  internationalMailOrderEnabled: boolean;
  showBankAccountsInWallet: boolean;
  showCashBack: boolean;
  showChangePayment: boolean;
  showInterventionFilters: boolean;
  smartCardEnabled: boolean;
}

export interface EndUser {
  address: Address | null;
  avatar: MediaFile | null;
  displayName: string;
  email: string | null;
  firstName: string | null;
  id: number | string;
  isEmailVerified: boolean;
  isMobileVerified: boolean;
  lastName: string | null;
  mobile: string | null;
  newEmail: string | null;
  newMobile: string | null;
  paymentCard: PaymentCard | null;
  type: UserType.END_USER;
  username: string;
}

export interface ErrorException extends Error {
  message: string;
}

export interface FileItem {
  file: FileMetadata;
  id: number | string;
  isUploading: boolean;
  origin: FileOrigin;
  percentage: number;
}

export interface FileMetadata {
  name: string;
  size: number;
}

export enum FileOrigin {
  LOCAL = "local",
  FROM_DATABASE = "from-database",
}

export interface FileUpload extends FileItem {
  connection: XMLHttpRequest | null;
}

export interface MailingAddress extends Address {
  firstName: string;
  lastName: string;
}

export interface Member {
  activatedOn: string;
  address: Address | null;
  alternateId: string | null;
  certNumber: string | null;
  claimMemberId: string | null;
  claimantNumber: string | null;
  createdOn: string;
  data: any;
  deactivatedOn: string | null;
  dob: string | null;
  employeeId: string | null;
  employer: string | null;
  employerGroup: number | null;
  familyId: string | null;
  gender: string | null;
  id: number;
  memberId: string;
  moneyTransmitter: string | null;
  personCode: string | null;
  referralCode: string | null;
  personalEmail: string | null;
  phiConsentOn: string | null;
  phoneNumber: string | null;
  planNumber: string | null;
  primaryMember: number | null;
  relationship: string | null;
  tosConsentOn: string | null;
  updatedOn: string | null;
  user: number | null;
  workEmail: string | null;
  paSupportEnabled: boolean | null;
}

export interface RequestSupportPayload {
  phoneNumber: string | null;
  preferredDay: string | null;
  preferredTime: string | null;
  memberNote: string | null;
}

export interface MemberUpdate {
  phiConsent: boolean;
}

export type User = Administrator | EndUser | ChatParticipantAgent;

export enum UserType {
  ADMINISTRATOR = "administrator",
  AGENT = "agent",
  END_USER = "end-user",
}

export enum MemberEvents {
  ACTIVATED = "ACTIVATED",
  CONFIRM_EMAIL_BUTTON_CLICKED = "CONFIRM_EMAIL_BUTTON_CLICKED",
  ONBOARDED = "ONBOARDED",
  USER_CONFIRMS_CURRENT_MEDS = "USER_CONFIRMS_CURRENT_MEDS",
  USER_CONFIRMS_PROFILE_DETAILS = "USER_CONFIRMS_PROFILE_DETAILS",
  USER_EXPLICIT_ACCEPTS_INTERVENTION = "USER_EXPLICIT_ACCEPTS_INTERVENTION",
  USER_IMPLICIT_ACCEPTS_INTERVENTION = "USER_IMPLICIT_ACCEPTS_INTERVENTION",
}
