import { AVATAR_AISHA_MCKAY_MOCK } from "src/common/mocks/avatar";
import { Agent } from "src/employee/portal/types";
import {
  ChatParticipantAgent,
  ChatParticipantType,
  UserType,
} from "src/common/types";

export const AGENT_AISHA_MCKAY_MOCK: Agent = {
  avatar: AVATAR_AISHA_MCKAY_MOCK,
  displayName: "Certified Pharmacy Technician",
  firstName: "",
  id: 5,
  lastName: "",
  role: "Member Care Team",
  type: UserType.AGENT,
};

export const CHAT_PARTICIPANT_AGENT_AISHA_MCKAY_MOCK: ChatParticipantAgent = {
  avatar: AVATAR_AISHA_MCKAY_MOCK,
  displayName: "Certified Pharmacy Technician",
  firstName: "",
  id: 5,
  lastName: "",
  role: "Member Care Team",
  type: ChatParticipantType.AGENT,
};
